/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.14.0-v202503281723-CDH
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

export interface DatafileInputV1 {
    "additionalProperties"?: Array<models.ScalarPropertyV1>;
    /**
     * If true, append the data in this CSV file to the signals or condition.
     */
    "append"?: boolean;
    /**
     * The name to use for the condition being imported. If this datafile already has a condition by this name, the import will modify the already existing condition rather than creating another condition with the same name. This setting is ignored when importing signals.
     */
    "conditionName"?: string;
    /**
     * The data ID of this item. Note: This is not the Seeq ID, but the unique identifier that the remote datasource uses.
     */
    "dataId"?: string;
    /**
     * If true, assume day first dates when ambiguous. If false (default), assume month first dates when ambiguous. For example, 07/01/16 is ambiguous and could be a day first or month first date. This setting is only used when there is not enough information in the column to distinguish month first from day first dates.
     */
    "dayFirstDefault"?: boolean;
    /**
     * Clarifying information or other plain language description of this item. An input of just whitespaces is equivalent to a null input.
     */
    "description"?: string;
    /**
     * Integer that identifies the row containing the description for each signal. If there is no such row, set to 0. If not specified, defaults to 0. (Row 1 is the first row of the file.) If importing a condition, the content of this row is ignored.
     */
    "descriptionRow"?: number;
    /**
     * Integer that identifies the column containing the capsule end key for the condition. If not specified, defaults to keyColumnIndex + 1. If endColumnName is specified, this setting will be ignored. If importing a signal, this setting is ignored.
     */
    "endColumnIndex"?: number;
    /**
     * The name of the column containing the capsule end key for the condition. If not specified or whitespace, the endColumnIndex will be used. If specified, the endColumnIndex will be ignored.
     */
    "endColumnName"?: string;
    /**
     * The character used as the CSV field delimiter. The possibilities are comma, semicolon, and tab. If not specified, defaults to comma.
     */
    "fieldDelimiter"?: FieldDelimiterEnum;
    /**
     * The name of the file that the client uploaded. Useful for keeping track of what file was used to create the Datafile.
     */
    "filename"?: string;
    /**
     * Integer that identifies the row at which to start reading the data. If not specified, defaults to 2. (Row 1 is the first row of the file.)
     */
    "firstDataRow"?: number;
    /**
     * The ID of the datasource hosting this item. Note that this is a Seeq-generated ID, not the way that the datasource identifies itself.
     */
    "hostId"?: string;
    /**
     * The interpolation method used to represent the values between samples in the signal. The possibilities are: Linear, PILinear, and Step. If not specified, defaults to Linear. If an interpolation method row is specified, the information in that row overrides this setting. If importing a condition, this setting is ignored.
     */
    "interpolationMethod"?: string;
    /**
     * Integer that identifies the row containing the interpolation method for each signal. If there is no such row, set to 0. If not specified, defaults to 0. If an interpolation method in the row is not recognized, the import will fail. If an interpolation method in the row is blank, the interpolationMethod setting will be used as the default. (Row 1 is the first row of the file.)If importing a condition, the content of this row is ignored.
     */
    "interpolationMethodRow"?: number;
    /**
     * The type of item to be imported from the CSV file. Supported types include signal and condition.
     */
    "itemType"?: ItemTypeEnum;
    /**
     * Integer that identifies the column containing the sample timestamps for the signal(s) or the column containing the capsule start key for the condition. If not specified, defaults to 1, the first column. If keyColumnName is specified, this setting will be ignored.
     */
    "keyColumnIndex"?: number;
    /**
     * The name of the column containing the signal timestamps for the signal(s) or the column containing the capsule start key for the condition. If not specified or whitespace, the keyColumnIndex will be used. If specified, the keyColumnIndex will be ignored.
     */
    "keyColumnName"?: string;
    /**
     * The format of the sample timestamps for signals or the format of the capsule start and end times for a condition. If not specified, defaults to ISO8601.
     */
    "keyFormat"?: KeyFormatEnum;
    /**
     * If true, hours are allowed that don't exist due to the spring forward daylight savings transition. They are interpreted as occurring in the following hour. The true setting should not be needed if the data was logged appropriately for its time zone. If false (default), data in hours that don't exist will cause the import to fail.
     */
    "lenientDaylightSavings"?: boolean;
    /**
     * The maximum duration of the capsules in the condition. Capsules greater than this duration will be imported but will not returned when data from the condition is requested. If importing a signal, this setting is ignored.
     */
    "maximumDuration"?: string;
    /**
     * The maximum spacing between adjacent sample keys that can be interpolated across. If two samples are spaced by more than maximum interpolation, there will be a hole in the signal between them. If not specified, defaults to 40h. If a maximum interpolation row is specified, the information in that row overrides this setting. If importing a condition, this setting is ignored.
     */
    "maximumInterpolation"?: string;
    /**
     * Integer that identifies the row containing the maximum interpolation for each signal. If there is no such row, set to 0. If not specified, defaults to 0. If a maximum duration in the row is not recognized, the import will fail. If an maximum interpolation in the row is blank, the maximumInterpolation setting will be used as the default. (Row 1 is the first row of the file.)If importing a condition, the content of this row is ignored.
     */
    "maximumInterpolationRow"?: number;
    /**
     * Human readable name. Required during creation. An input of just whitespaces is equivalent to a null input.
     */
    "name": string;
    /**
     * Prefix prepended to the name of each signal when importing signal(s) and prepended to the name of each capsule property when importing a condition. Leading whitespace is ignored.
     */
    "namePrefix"?: string;
    /**
     * Integer that identifies the header row used to name the signal(s) when importing signal(s) and used to name the capsule properties when importing a condition. If not specified, defaults to 1 (first row). The name row is required and must have unique non-whitespace entries.
     */
    "nameRow"?: number;
    /**
     * Suffix appended to the name of each signal when importing signal(s) and appended to the name of each capsule property when importing a condition. Trailing whitespace is ignored.
     */
    "nameSuffix"?: string;
    "properties"?: Array<models.ScalarPropertyV1>;
    /**
     * The ID of the workbook to which this item will be scoped.
     */
    "scopedTo"?: string;
    /**
     * If the timestamps (key for signals, start/end for a condition) contain no time zone information, they will be interpreted as being in this time zone.  If not specified and the timestamps contain no time zone information, the time zone of the Seeq server is used. If the timestamps contain time zone information, this setting is ignored.
     */
    "timeZone"?: string;
    /**
     * The server-side name of the CSV file that is returned from the upload endpoint.
     */
    "uploadFilename": string;
    /**
     * The approach to use when CSV data cannot be parsed. Choices are Fail, Skip, Invalid. If Fail (default), then cells that cannot be parsed will cause the import to fail with error messages.If Skip, those cells will be skipped meaning that no sample will be created for signals from that row of the file. For conditions, if it is the start or end cell, no capsule will be created from that row. If the cell is a capsule property, the capsule is still created but without that capsule property. If Invalid and the cell is a sample key or capsule start/end, no sample or capsule is created from that row of the file. If the cell is a sample value or capsule property, the sample or capsule property is created with the value INVALID.
     */
    "validationMode"?: ValidationModeEnum;
    /**
     * List of integers identifying the columns to import. When importing signals, these columns will be combined with the key column to create signals. When importing a condition, these columns will become the capsule properties. Valid formats are a comma separated list of 'N' or 'N-M' where N and M are integers greater than zero and M >= N. Example: '2, 5-7, 10, 12-14'. The first column of the file is column 1. If the column(s) representing a signal key or condition start/end is included in the list, it will be ignored. If neither valueColumnNames nor valueColumnIndices are specified, all columns other than the key/start/end column will result in signals when importing signals and will result in capsule properties when importing a condition. An entry of 0 alone indicates that no columns should be imported as capsule properties. If a column in this list cannot be found in the file, the import will fail. Any column is only imported once no matter how many times it is listed.
     */
    "valueColumnIndices"?: string;
    /**
     * List of comma separated case sensitive names of the columns to import. When importing signals, these columns will be combined with the key column to create signals. When importing a condition, these columns will become the capsule properties. If the column(s) representing a signal key or condition start/end is included in the list, it will be ignored. If not specified, valueColumnIndices will be used. If specified, valueColumnIndices will be ignored. If a column name in this list cannot be found in the file, the import will fail. Any column is only imported once no matter how many times it is listed.
     */
    "valueColumnNames"?: string;
    /**
     * The unit of measure to be used for every signal when importing signals and for every capsule property when importing a condition. If not specified, defaults to unitless. If a unit of measure row is specified, the information in that row overrides this setting. If this unit of measure is not recognized, the import will fail.
     */
    "valueUom"?: string;
    /**
     * Integer that identifies the row containing the unit of measure for each signal when importing signal(s) or for each capsule property when importing a condition. If there is no such row, set to 0. If not specified, defaults to 0. If a unit of measure in the row is not recognized, unitless will be used instead. (Row 1 is the first row of the file.)
     */
    "valueUomRow"?: number;
}

export enum FieldDelimiterEnum {
    Comma = 'Comma' as any,
    Semicolon = 'Semicolon' as any,
    Tab = 'Tab' as any
}
export enum ItemTypeEnum {
    Signal = 'Signal' as any,
    Condition = 'Condition' as any,
    SupportedItemType = 'SupportedItemType' as any
}
export enum KeyFormatEnum {
    ISO8601 = 'ISO8601' as any,
    MONTHDAYYEAR24HRCLOCK = 'MONTH_DAY_YEAR_24HRCLOCK' as any,
    MONTHDAYYEAR12HRCLOCK = 'MONTH_DAY_YEAR_12HRCLOCK' as any,
    UNIXEPOCHSECONDS = 'UNIX_EPOCH_SECONDS' as any,
    KeyFormatType = 'KeyFormatType' as any
}
export enum ValidationModeEnum {
    Fail = 'Fail' as any,
    Skip = 'Skip' as any,
    Invalid = 'Invalid' as any
}
