/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.14.0-v202503281723-CDH
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqMonitorsApiClass {
 
   constructor() {}

  /**
   * @summary Retrieve the current value of the specified monitor. All times are in milliseconds
   * @param {string} monitorPath - Path of the monitor to read
   */
  public getMonitor(
    {
      monitorPath
    } : {
      monitorPath: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(monitorPath)) {
      throw new Error("'monitorPath' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/monitors/${encodeURIComponent(String(monitorPath))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.MonitorOutputV1>;
  }

  /**
   * @summary Retrieve the documentation for all available monitors
   */
  public getMonitorDocumentation(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/monitors/definitions`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.MonitorDefinitionsOutputV1>;
  }

  /**
   * @summary Retrieve the paths of all available monitors
   */
  public getMonitors(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/monitors`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.MonitorsOutputV1>;
  }

  /**
   * @summary Update a gauge
   */
  public postGaugeData(
    body?: models.GaugeDatumV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/system/monitors/gauge`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Increment a meter
   */
  public postMeterData(
    body?: models.MeterDatumV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/system/monitors/meter`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Post monitor data
   */
  public postMonitorData(
    body: models.MonitorInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/system/monitors`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Update a timer
   */
  public postTimerData(
    body: models.TimerDatumV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/system/monitors/timer`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

}


export const sqMonitorsApi = new sqMonitorsApiClass();
